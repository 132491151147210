import React, { useEffect, useState } from "react";
import Testimonials from 'react-testimonials';
import './css/Benefits.css';
import { SectionTitle } from './CommonsComponents'
import { useTranslation } from 'react-i18next';

export default function Benefits(props) {

    const { t } = useTranslation();

    const img1 = process.env.PUBLIC_URL  +'/images/android.svg';
    const img2 = process.env.PUBLIC_URL  +'/images/wpp.svg';
    const img3 = process.env.PUBLIC_URL  +'/images/insta.svg';
    const img4 = process.env.PUBLIC_URL  +'/images/face.svg';
    const img5 = process.env.PUBLIC_URL  +'/images/location.svg';
    const img6 = process.env.PUBLIC_URL  +'/images/eye.svg';
    const img7 = process.env.PUBLIC_URL  +'/images/pictures.svg';
    const img8 = process.env.PUBLIC_URL  +'/images/panel.svg';
    const img9 = process.env.PUBLIC_URL  +'/images/ai.svg';
    const img10 = process.env.PUBLIC_URL  +'/images/protected.svg';

  
    const review1=[img1, t('benefits.review1.title'), "", t('benefits.review1.description')];
    const review2=[img2, t('benefits.review2.title'), "", t('benefits.review2.description')];
    const review3=[img3, t('benefits.review3.title'), "", t('benefits.review3.description')];
    const review4=[img4, t('benefits.review4.title'), "", t('benefits.review4.description')];
    const review5=[img5, t('benefits.review5.title'), "", t('benefits.review5.description')];
    const review6=[img6, t('benefits.review6.title'), "", t('benefits.review6.description')];
    const review7=[img7, t('benefits.review7.title'), "", t('benefits.review7.description')];
    const review8=[img8, t('benefits.review8.title'), "", t('benefits.review8.description')];
    const review9=[img9, t('benefits.review9.title'), "", t('benefits.review9.description')];
    const review10=[img10, t('benefits.review10.title'), "", t('benefits.review10.description')];

    const items=[review1,review2,review3,review4,review5,review6,review7,review8,review9,review10];
  
    const randomizeArray = (array) => {
      return array.sort(() => Math.random() - 0.5);
    };
    randomizeArray(items);

    return (
      <div className=" scrollMargin mt-4 pb-4 small">
        <h5 className="text-center text-uppercase">{t('benefits.title')}</h5>
        {/* <SectionTitle name={t('benefits.title')} /> */}
        <Testimonials items={items} />
      </div>
    );

}