import React, { useEffect, useState } from "react";
import { Fade, Bounce, Zoom } from 'react-reveal';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook



export function StepNumber(props) {
    let { number, title, color } = props


    let aColor = "rgba(7, 65, 121, 0.8)";
    let animate = "blob blue"
    if (color) {
        aColor = color
        animate = ""
    };

    return (
        <div className='d-flex mt-2'>
            <Zoom>
                <div className='' style={{ width: "50px", height: "50px", alignContent: "center", paddingLeft: "3.2px", borderRadius: "25px", color: "#fff", border: "2px solid " + aColor }}>
                    <div className={"text-center font-weight-bold " + animate} style={{ width: "40px", height: "40px", alignContent: "center", fontSize: "20px", borderRadius: "20px", color: "#fff", border: "2px solid " + aColor, backgroundColor: aColor }}>
                        {number}
                    </div>
                </div>
                <div className='ml-2 pt-1 text-light font-weight-bold text-uppercase' style={{ alignContent: "center", fontSize: "15px", letterSpacing: "0.15em" }}>{title}</div>
            </Zoom>
        </div>
    )
}

export function ButtonGoUp(props) {
    // const { t } = useTranslation();

    const [showDiv, setShowDiv] = useState(false);

    useEffect(() => {
        document.addEventListener("scroll", displayGoUpButton);
    }, [])



    const goUp = () => {
        //window.scrollTo({ top:0});
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }

    const displayGoUpButton = () => {
        if (window.scrollY > 300) {
            setShowDiv(true);
        } else {
            setShowDiv(false);
        }
    }

    if (!showDiv)
        return null;

    return (

        <div className="d-flex justify-content-end fixed-bottom " style={{ width: "", alignSelf: 'end' }} >
            <span onClick={() => goUp()} className=" px-2 rounded-pill my-2 mx-2 " style={{ backgroundColor: "#000", opacity: '0.6' }}><i className="fa fa-chevron-up fa-2x" /></span>
        </div>

    )

}

export function SectionTitle(props) {
    let { name } = props
    return (
        <React.Fragment>
            <hr className="bg-secondary" />
            <h2 className="text-light text-uppercase"><i className="fa fa-chevron-right mr-2 text-success " />{name}</h2>
        </React.Fragment>
    )
}


export function Loading() {
    const { t } = useTranslation(); // Get the translation function
    return (
        <Fade>
            <div className='container text-center mt-4 text-light' style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                <div>
                    <img src={process.env.PUBLIC_URL + "/images/logo.svg"} className="img-responsive" width="100px" />
                </div>
                <img style={{ marginLeft: "5px", marginTop: "5px" }} src={process.env.PUBLIC_URL + "/images/logotext.png"} className="img-responsive" width="180px" />
                <h3 className='text-danger pt-2 text-uppercase font-weight-bold'><i className='fa fa-spinner fa-spin mr-2 mt-4' />{t("loadingComponent")}</h3>
            </div>
        </Fade>
    )
}
