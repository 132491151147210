import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

import Fade from 'react-reveal/Fade';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import { StepNumber } from './CommonsComponents';

import './css/ModoDeUso.css';
import { SectionTitle } from './CommonsComponents';

export default function ModeOfUse(props) {
    const { t } = useTranslation(); // Get the translation function
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + ' font-weight-bold ">' + (index + 1) + '</span>';
        },
    };

    let septs = [
        {
            icon: "fa fa-download",
            title: t('mododeuso.download_title'),
            description: t('mododeuso.download_description')
        },
        {
            icon: "fa fa-mobile",
            title: t('mododeuso.install_title'),
            description: t('mododeuso.install_description')
        },
        {
            icon: "fa fa-cogs",
            title: t('mododeuso.instructions_title'),
            description: t('mododeuso.instructions_description')
        },
        {
            icon: "fa fa-eye",
            title: t('mododeuso.monitor_title'),
            description: t('mododeuso.monitor_description')
        }
    ];

    return (
        <div className='container scrollMargin col-md-6' id="use">
            <Fade>
                <SectionTitle name={t('mododeuso.section_title')} />
                <div className="my-2 text-light p-1 px-2" style={{ fontSize: "0.8em", maxWidth: "700px", textJustify:"inter-word", textAlign:"justify" }}>
                    {t('mododeuso.intro_text')}
                </div>

                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: true,
                    }}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={pagination}
                    modules={[EffectCoverflow, Pagination, Autoplay]}
                    className="mySwiper"
                >
                    {septs.map((sept, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className='bg-success text-dark h-100 rounded p-3 blueShadow'>
                                    <StepNumber number={index + 1} color={"#18191A"} />
                                    <div className="d-flex justify-content-center align-items-center flex-column ">
                                        <i style={{ fontSize: "80px" }} className={sept.icon + ' text-dark pb-2'} />
                                        <div className="text-uppercase font-weight-bold mb-2">{sept.title}</div>
                                        <div className='' style={{ fontSize: "13px" }}>{sept.description}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </Fade>
        </div>
    )
}