import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import ReactGA from "react-ga4";

import Header from './Components/Header';
import Top from './Components/Top';
import Features from './Components/Features';
import { ButtonGoUp ,Loading } from './Components/CommonsComponents';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';

import ModeOfUse from './Components/ModeOfUse';
import Paralax from './Components/Paralax';
import Download from './Components/Download';
import Benefits from './Components/Benefits';
import { t } from 'i18next';



//Lazy loading
const Legal = lazy(() => import('./Components/Legal'));
const Suport = lazy(() => import('./Components/Suport'));
const Reseller = lazy(() => import('./Components/Reseller'));
const ServiceStatus = lazy(() => import('./Components/ServiceStatus'));
const AndroidClient = lazy(() => import('./Components/AndroidClient'));
//-------



function App() {

  const location = useLocation()

  const domains = [
    { url: "cwf-landing-page.web.app", GA4_ID: "G-3PM3204Z7L" },
    { url: "catwatchful.es", GA4_ID: "G-L7324FXXM6" },
    { url: "catwatchful.com", GA4_ID: "G-6EWJR2XLJV" },
    { url: "cat-watch-app.net", GA4_ID: "G-0VDS573JH8" },
    { url: "cat-watch-app.org", GA4_ID: "G-0474NV98ZK" },
    // { url: "localhost", GA4_ID: "G-3PM3204Z7L" },

  ]


  useEffect(() => {

    const currentUrl = window.location.hostname

    let GA4_ID = domains.find(d => d.url === currentUrl)?.GA4_ID
    if (GA4_ID) {
      console.log("GA4_ID", GA4_ID)
      //INIZIALIZO GA4 CON EL CORRESPONDIENTE ID
      ReactGA.initialize(GA4_ID);
      //******** */
    } else {
      console.log("GA4_ID not found for", currentUrl)
    }


    
    document.title = t('pageTitle')
  }, [])





  useEffect(() => {
    let title = `${location.pathname} ${location.hash}`
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: title });
    console.log("GA4", title)
  }, [location.pathname])




  useEffect(() => {
    let currentUrl = window.location.href
    console.log("currentUrl", currentUrl)
    if (currentUrl.includes("#")) {
      let section = currentUrl.split("#")[1]
      let element = document.getElementById(section)
      if (element) element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [window.location.href])





  return (
    <div className="App" id="home" style={{scrollMargin:"100px"}}>
      

      <Header />

      <Routes >
        <Route path="/" element={<HomePage />} />
        <Route path="/legal" element={<Suspense fallback={<Loading />}><Legal /></Suspense>} />
        <Route path="/suport" element={<Suspense fallback={<Loading />}><Suport /></Suspense>} />
        <Route path="/reseller" element={<Suspense fallback={<Loading />}><Reseller /></Suspense>} />
        <Route path="/service-status" element={<Suspense fallback={<Loading />}><ServiceStatus /></Suspense>} />
        <Route path="/android-client" element={<Suspense fallback={<Loading />}><AndroidClient /></Suspense>} />

        <Route path="/features" element={<Suspense fallback={<Loading />}><Features fullMode={true} /></Suspense>} />
        <Route path="/download" element={<Suspense fallback={<Loading />}><Download fullMode={true} /></Suspense>} />
        <Route path="/faq" element={<Suspense fallback={<Loading />}><FAQ fullMode={true} /></Suspense>} />
        <Route path="/modeOfUse" element={<Suspense fallback={<Loading />}><ModeOfUse fullMode={true} /></Suspense>} />


        <Route path="*" element={<HomePage />} />
      </Routes>



      <ButtonGoUp />
      <Footer />

    </div>


  );


}


function HomePage() {
  return (
    <>
      <Top />
      <Paralax />
      <ModeOfUse />
      <Download ReactGA={ReactGA} />
     
      <Features />
      <FAQ />
      <Benefits />

    </>
  );
}




export default App;
