import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './css/Paralax.css';

// import required modules
import { Parallax, Pagination, Navigation,Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next'; // Importar hook de traducción

export default function Paralax() {
  const { t } = useTranslation(); // Obteniendo función de traducción

  return (
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        speed={600}
        parallax={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        navigation={true}
        modules={[Parallax, Pagination, Navigation,Autoplay]}
        className="mySwiper"
      >
        <div
          slot="container-start"
          className="parallax-bg overlay"
          style={{
            'background-image': `url(${process.env.PUBLIC_URL}/images/paralax.jpg)`
              // 'url(https://catwatchful.es/wp-content/uploads/2022/05/pexels-photo-3103199.jpeg)',
          }}
          data-swiper-parallax="-23%"
        ></div>
        <SwiperSlide>
          <div className='text-light px-2 container blackOverlay h-100'>
            <div className="title" data-swiper-parallax="-500">
              {t('paralax.slide1.title')}
            </div>
            <div className="subtitle" data-swiper-parallax="-300">
              {t('paralax.slide1.subtitle')}
            </div>
            <div className="text mt-3" data-swiper-parallax="-100">
              <p>{t('paralax.slide1.text')}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='text-light px-2 container blackOverlay h-100'>
            <div className="title" data-swiper-parallax="-300">
              {t('paralax.slide2.title')}
            </div>
            <div className="subtitle" data-swiper-parallax="-200">
              {t('paralax.slide2.subtitle')}
            </div>
            <div className="text mt-3" data-swiper-parallax="-100">
              <p>{t('paralax.slide2.text')}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='text-light px-2 container blackOverlay h-100'>
            <div className="title" data-swiper-parallax="-300">
              {t('paralax.slide3.title')}
            </div>
            <div className="subtitle" data-swiper-parallax="-200">
              {t('paralax.slide3.subtitle')}
            </div>
            <div className="text mt-3" data-swiper-parallax="-100">
              <p>{t('paralax.slide3.text')}</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='text-light px-2 container blackOverlay h-100'>
            <div className="title" data-swiper-parallax="-300">
              {t('paralax.slide4.title')}
            </div>
            <div className="subtitle" data-swiper-parallax="-200">
              {t('paralax.slide4.subtitle')}
            </div>
            <div className="text mt-3" data-swiper-parallax="-100">
              <p>{t('paralax.slide4.text')}</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}