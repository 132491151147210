import React from "react";
import { Bounce } from "react-reveal";
import { InstagramEmbed, YouTubeEmbed, FacebookEmbed } from 'react-social-media-embed';
import { useTranslation } from 'react-i18next';

export default function SocialMedia(props) {

    const { t } = useTranslation();

    let socialMedia = [
        { name: "instagram", url: "https://www.instagram.com/catwatchful_es", icon: "fa fa-instagram" },
        { name: "facebook", url: "https://www.facebook.com/cwatchful.esp/", icon: "fa fa-facebook" },
        { name: "twitter", url: "https://twitter.com/catwatchful", icon: "fa fa-twitter" },
        { name: "youtube", url: "https://www.youtube.com/results?search_query=catwatchful", icon: "fa fa-youtube" }

    ]


    return (
        <div id="socialmedia" className="container col-md-8 pb-4" >

            {/* <div className="text-center mb-1">
                <span style={{fontSize:"12px"}} className="font-weight-bold">{t("socialMedia.fallowUs")}: </span>
            </div> */}

            <div className="d-flex justify-content-center">
                <Bounce top >
                    <div className="d-flex justify-content-between" style={{ width: "250px" }}>
                        {socialMedia.map((item, index) => {
                            return (
                                <span key={index}  className="text-center faa-circle blackGradient  grayBorder" style={{ width: "40px", height: "40px", paddingTop: "10px" }}>
                                    <a href={item.url} target="_blank" >
                                        <i style={{ fontSize: "20px" }} className={item.icon + " text-light"} />
                                    </a>
                                </span>
                            )
                        }
                        )}
                    </div>
                </Bounce>
            </div>


            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <InstagramEmbed url="https://www.instagram.com/catwatchful_es/" width={328} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <YouTubeEmbed url="https://www.youtube.com/watch?v=uUD8pI6Zhao" width={325} height={220} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FacebookEmbed url="https://www.facebook.com/cwatchful.esp/posts/pfbid0B9A6Kh2bGHqQ2aZSBCvWVqu946ZMLoM2YUZQAGyAumpYUmcycRh77RznhiXA8Au8l" width={325} height={220} />
            </div>
             */}
        </div>
    )

}