import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'; // Importar hook de i18next
// import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg'
// import { ReactComponent as Brand } from '../../assets/icons/logo.svg'
import { Fade, Zoom, Flip ,Rotate} from "react-reveal";
import './css/Header.css'

export default function Header (props) {
  const { t } = useTranslation(); // Obtener función de traducción
  const [showNavbar, setShowNavbar] = useState(false)
  const navigate = useNavigate();

  const [aLogo, setALogo] = useState(process.env.PUBLIC_URL + "/images/logo-horizontal-solo.png")

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }



  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 270 )  {
        setALogo(process.env.PUBLIC_URL + "/images/logo-horizontal.png")
      } else {
        setALogo(process.env.PUBLIC_URL + "/images/logo-horizontal-solo.png")
      }
    })

  }, [])



  const goTo = (section) => () => {
    setShowNavbar(false)
    if (section.includes("/")) {
      navigate(section)
    } else {
      navigate(`/#${section}`)
    }
  }

  return (
    <nav className="navbar blackGradient " >
      <div className="container" >
        <div className="logo" onClick={goTo("home")}>
          {/* <Brand /> */}
          {/* <img src= {process.env.PUBLIC_URL + "/images/logo-horizontal.png"} className="img-responsive" width="150px" /> */}
        <img src= {aLogo} className="img-responsive" width="130px" />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          {showNavbar ? <Rotate duration="450"><i className="fas fa-times"></i></Rotate> : <i className="fas fa-bars text-light"></i>}
          {/* <Hamburger /> */}
        </div>


        <div className={`nav-elements ${showNavbar && 'active'}`} onClick={handleShowNavbar} >
          <ul>
            <li className=''>
              <a href='https://cp.catwatchful.com' target='_blank' >{t('header.login')}</a>
            </li>
            <li>
              <a onClick={goTo("download")} >{t('header.download')}</a>
            </li>
            <li>
              <a onClick={goTo("features")} >{t('header.features')}</a>
            </li>
            <li>
              <a onClick={goTo("use")}>{t('header.modeOfUse')}</a>
            </li>
            <li>
              <Link to="https://buy.catwatchful.com" >{t('header.prices')}</Link>
              {/* <a href='https://buy.catwatchful.com'>{t('header.prices')}</a> */}
            </li>
            <li>
              <a onClick={goTo("faq")} >{t('header.faq')}</a>
            </li>
            <li>
              <a onClick={goTo("/suport")} >{t('header.support')}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}