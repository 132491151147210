import React from "react";
import { useTranslation } from "react-i18next";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SectionTitle } from './CommonsComponents'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Fade } from "react-reveal";

export default function FAQ(props) {
    const { t } = useTranslation();
    const { fullMode } = props

    const faqs = [
        {
            title: t('faq.question1.title'),
            description: t('faq.question1.description'),
        },
        {
            title: t('faq.question2.title'),
            description: t('faq.question2.description'),
        },
        {
            title: t('faq.question3.title'),
            description: t('faq.question3.description'),
        },
        {
            title: t('faq.question4.title'),
            description: t('faq.question4.description'),
        },
        {
            title: t('faq.question5.title'),
            description: t('faq.question5.description'),
        },
        {
            title: t('faq.question6.title'),
            description: t('faq.question6.description'),
        },
        {
            title: t('faq.question7.title'),
            description: t('faq.question7.description'),
        },
        {
            title: t('faq.question8.title'),
            description: t('faq.question8.description'),
        },
        {
            title: t('faq.question9.title'),
            description: t('faq.question9.description'),
        },
        {
            title: t('faq.question10.title'),
            description: t('faq.question10.description'),
        },
        {
            title: t('faq.question11.title'),
            description: t('faq.question11.description'),
        },
        {
            title: t('faq.question12.title'),
            description: t('faq.question12.description'),
        },
        {
            title: t('faq.question13.title'),
            description: t('faq.question13.description'),
        },
        {
            title: t('faq.question14.title'),
            description: t('faq.question14.description'),
        },
        {
            title: t('faq.question15.title'),
            description: t('faq.question15.description'),
        },
        {
            title: t('faq.question16.title'),
            description: t('faq.question16.description'),
        },
        {
            title: t('faq.question17.title'),
            description: t('faq.question17.description'),
        },
        {
            title: t('faq.question18.title'),
            description: t('faq.question18.description'),
        },
        {
            title: t('faq.question19.title'),
            description: t('faq.question19.description'),
        },
        {
            title: t('faq.question20.title'),
            description: t('faq.question20.description'),
        },
        {
            title: t('faq.question21.title'),
            description: t('faq.question21.description'),
        },
        {
            title: t('faq.question22.title'),
            description: t('faq.question22.description'),
        },
        {
            title: t('faq.question23.title'),
            description: t('faq.question23.description'),
        }
    ];

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <div id="faq" className="container scrollMargin col-md-6 mt-4 pb-4 " >
            <SectionTitle name={t('faq.section.title')} />


            {fullMode && <div className="text-center my-3">
                {t('faq.description')}
            </div>}

            <Fade>
                <div>
                    <ThemeProvider theme={darkTheme}>

                        {faqs.map((faq, index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color='primary' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <span className="text-uppercase " style={{ fontSize: "13px", color: "#b6c7cf" }}>{faq.title}</span>
                                </AccordionSummary>
                                <div style={{ backgroundColor: "#0f1215" }}>
                                    <AccordionDetails>
                                        <div style={{ fontSize: "13px", color: "#fff" }}>
                                            {faq.description}
                                        </div>
                                    </AccordionDetails>
                                </div>
                            </Accordion>
                        ))}

                    </ThemeProvider>
                </div>
            </Fade>

        </div>
    )
}